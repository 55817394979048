@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

footer {
    padding-top: 2rem;
    height: 200px;
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-around;
    background-color: $background-color-light-darker;
    z-index: 9999;

    @include hm {
        flex-direction: column;
        height: 30rem;
        padding-top: .5rem;
        align-items: center;
    }

    .section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include hm {
            width: 60%;
            align-items: center;
            justify-content: center;
        }

        @include sm {
            width: 80%;
        }

        @include xs {
            width: 90%;
        }

        h1 {
            font-size: $text-size-14;
            color: $text-color-grey;

            @include hm {
                font-size: $text-size-16;
            }
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: flex;
                font-size: $text-size-10;

                a {
                    color: $text-color-grey;
                    text-decoration: none;
                }

                img {
                    width: 20px;
                    margin-right: 5px;
                }
            }
        }

        ul.socials {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 14rem;

            li {
                img {
                    width: 40px;
                    transition: transform .2s ease-in-out;
                }

                img:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.bottom-line {
    border-top: 1px solid rgb(212, 211, 211);
    height: 60px;
    width: 100%;
    background-color: $background-color-light-darker;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include sm {
        padding: 2rem 0;
    }
}

#dark {
    footer {
        background-color: $background-color-dark-darker;

        .section {
            h1 {
                color: $text-color-white;
            }

            ul {
                li {
                    color: $text-color-white;

                    a {
                        color: $text-color-white;
                    }

                    .prefix {
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                    }
                }
            }
        }
    }

    .bottom-line {
        background-color: $background-color-dark-darker;
        color: $text-color-white;
    }
}