@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.scroll-button {
    width: 70px;
    height: 70px;
    background-color: rgb(190, 190, 190);
    position: fixed;
    border-radius: 15px;
    bottom: 50px;
    right: 50px;
    display: flex;
    transition: opacity .5s ease-in-out;
    z-index: 999999;

    @include md {
        right: 40px;
    }

    @include sm {
        right: 30px;
        bottom: 30px;
    }

    &-arrow {
        position: relative;
        width: 20px;
        height: 20px;
        border-left: 2px solid $background-color-dark-darker;
        border-top: 2px solid $background-color-dark-darker;
        top: 65%;
        left: 34%;
        transform: rotate(45deg) translate(-50%, -50%);
        transition: top .2s ease-in-out;
    }

    &:hover {
        .scroll-button-arrow {
            top: 60%;
        }
    }
}

#dark {
    .scroll-button {
        border: 1px solid $background-color-dark-lighter;
        background-color: $background-color-dark-darker;

        &-arrow {
            border-left: 2px solid $background-color-light;
            border-top: 2px solid $background-color-light;
        }
    }
}