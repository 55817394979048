@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.paging {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: opacity .3s ease-in-out;

    &-button {
        display: inline-block;
        padding: 2rem .3rem;

        &.current {
            &::after {
                content: "";
                position: relative;
                display: block;
                border: 1px solid $text-color-grey;
            }
        }

        button {
            cursor: pointer;
            background-color: transparent;
            border: none;
            box-shadow: 1px 1px 4px $card-shadow-color-light;
            width: 35px;
            height: 35px;
        }
    }

    &-arrow {
        cursor: pointer;
        padding: 0 1rem;

        &::after {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            border-top: 3px solid $text-color-grey;
            border-left: 3px solid $text-color-grey;
        }

        &.next {
            &::after {
                transform: rotate(135deg);
            }
        }

        &.back {
            &::after {
                transform: rotate(-45deg);
            }
        }
    }
}

#dark {
    .paging {
        &-button {
            &.current {
                &::after {
                    border: 1px solid $text-color-white;
                }
            }

            button {
                color: $text-color-white;
                box-shadow: 1px 1px 4px $card-shadow-color-dark-hover;
            }
        }

        &-arrow {
            &::after {
                border-top: 3px solid $text-color-white;
                border-left: 3px solid $text-color-white;
            }
        }
    }
}