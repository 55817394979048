@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

$text-color-white: #fff;
$text-color-dark-white: rgb(192, 192, 192);
$text-color-black: #000;
$text-color-grey: rgb(88, 87, 87);
$text-color-light-grey: rgb(164 163 161);
$text-color-red: rgb(196, 0, 0);
$text-color-green: rgb(0, 199, 0);

$text-weight-100: 100;
$text-weight-200: 200;
$text-weight-300: 300;
$text-weight-400: 400;
$text-weight-500: 500;
$text-weight-600: 600;
$text-weight-700: 700;
$text-weight-800: 800;
$text-weight-900: 900;
$text-weight-1000: 1000;

$text-size-6: .6rem;
$text-size-8: .8rem;
$text-size-9: .9rem;
$text-size-10: 1.0rem;
$text-size-11: 1.1rem;
$text-size-12: 1.2rem;
$text-size-14: 1.4rem;
$text-size-16: 1.6rem;
$text-size-18: 1.8rem;
$text-size-20: 2.0rem;
$text-size-22: 2.2rem;
$text-size-24: 2.4rem;
$text-size-26: 2.6rem;
$text-size-28: 2.8rem;
$text-size-30: 3.0rem;