@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.ocommittee {
    &-top-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 2rem 6rem;
        justify-content: space-between;
        height: 10rem;

        @include sm {
            padding: 2rem 1rem;
            align-items: center;
            justify-content: end;
            flex-direction: column;
        }

        h1 {
            color: $text-color-grey;
            font-size: $text-size-26;
            padding: 0;
            margin: 0;

            @include sm {
                font-size: $text-size-20;
                text-align: center;
            }
        }
    }

    ul {
        list-style-type: none;
    }

    &-name {
        font-size: $text-size-12;
        font-weight: $text-weight-600;
        text-align: center;
        margin-bottom: 5px;
        color: $text-color-grey;
    }

    &-college {
        font-size: $text-size-10;
        text-align: center;
        color: $text-color-grey;

        &-city {
            text-align: center;
            color: $text-color-light-grey;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $background-color-light;
        padding: 1rem 0 3rem 0;
        min-height: 669px;

        .no-data {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100%;
            color: $text-color-grey;
            font-size: 1.4rem;
            line-height: 2rem;
            margin-top: 2rem;
        }

        &-student-title {
            font-size: $text-size-16;
            color: $text-color-grey;

            @include sm {
                width: 95%;
                margin: 0 auto 15px auto;
                font-size: $text-size-14;
                text-align: center;
            }
        }

        &-student-list {
            padding: 0;
            margin: 0;

            &-element {
                text-align: center;
                margin-bottom: 20px;
                font-size: $text-size-10;
                color: $text-color-grey;
            }
        }

        &-academic {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 10px 0 25px 0;

            @include sm {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

#dark {
    .ocommittee {
        &-top-bar {
            h1 {
                color: $text-color-white;
                font-size: $text-size-26;
            }
        }

        &-name {
            color: $text-color-white;
        }

        &-college {
            color: $text-color-white;

            &-city {
                color: $text-color-light-grey;
            }
        }

        &-content {
            background: $background-color-dark-lighter;

            .no-data {
                color: $text-color-white;
            }

            &-student-title {
                color: $text-color-white;
            }

            &-student-list {
                &-element {
                    color: $text-color-white;
                }
            }
        }
    }
}