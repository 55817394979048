@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.notfound {
    &-top-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 2rem 6rem;
        justify-content: space-between;
        height: 10rem;

        @include sm {
            flex-direction: column;
            align-items: center;
            justify-content: end;
            padding: 2rem 1rem;
        }

        h1 {
            color: $text-color-grey;
            font-size: $text-size-26;
            padding: 0;
            margin: 0;

            @include sm {
                font-size: $text-size-22;
            }
        }
    }

    &-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: $background-color-light;
        min-height: 750px;
    }

    &-wrapper {
        padding-top: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-title {
        margin: 0;
        font-size: $text-size-30;
        font-weight: $text-weight-600;
        color: $text-color-grey;
        margin-bottom: .2rem;
    }

    &-text {
        font-size: $text-size-16;
        font-weight: $text-weight-600;
        color: $text-color-grey;
        margin-bottom: 2rem;
    }

    &-button {
        padding: 0 2rem;
        width: 100%;
        height: 2.4rem;
        border-radius: 30px;
        border: none;
        font-size: $text-size-12;
        color: $text-color-white;
        background-color: $button-color-gradient-left;
        background-image: linear-gradient(220deg, $button-color-gradient-right 6%, $button-color-gradient-left 100%);
        cursor: pointer;
    }
}

#dark {
    .notfound {
        &-top-bar {
            h1 {
                color: $text-color-white;
            }
        }

        &-main {
            background-color: $background-color-dark-lighter;
        }

        &-title {
            color: $text-color-white;
        }

        &-text {
            color: $text-color-white;
        }
    }
}