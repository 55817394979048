@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.main-button {
    cursor: pointer;
    width: fit-content;
    height: 60px;
    border-radius: 40px;
    padding: 0 1rem;
    border: none;
    background-color: $button-color-gradient-left;
    background-image: linear-gradient(220deg, $button-color-gradient-right 6%, $button-color-gradient-left 100%);
    font-size: $text-size-12;
    font-weight: $text-weight-600;
    letter-spacing: 1px;
    color: $text-color-white;

    @include md {
        width: 400px;
    }

    @include sm {
        width: 80%;
        margin: 0 auto;
    }
}