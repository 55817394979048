@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.archives {
    &-top-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 2rem 6rem;
        justify-content: space-between;
        height: 10rem;

        @include sm {
            flex-direction: column;
            align-items: center;
            justify-content: end;
            padding: 2rem 1rem;
        }

        h1 {
            color: $text-color-grey;
            font-size: $text-size-26;
            padding: 0;
            margin: 0;

            @include sm {
                font-size: $text-size-22;
            }
        }
    }

    &-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: $background-color-light;
        min-height: 750px;
    }
}

#dark {
    .archives {
        &-top-bar {
            h1 {
                color: $text-color-white;
            }
        }

        &-main {
            background-color: $background-color-dark-lighter;
        }
    }
}