@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.header {
    position: fixed;
    top: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    z-index: 200;
    transition: all .3s ease-in-out;

    .options {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
        margin-right: 2rem;
        gap: 10px;
        width: 17vw;

        @include hm {
            width: 85%;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            justify-content: center;
            margin-right: 0;
            margin-top: 2rem;

            &.transition {
                transition: transform .4s ease-in-out;
            }

            &.open {
                transform: translateX(0);
            }
        }

        @include xxs {
            top: 80vh;
        }
    }
}

#dark {
    .header {
        background-color: $background-color-dark-darker;
    }
}

#light {
    .header {
        background-color: $background-color-light-header;
    }
}