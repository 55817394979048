@import '../../../../styles/utils/colors.scss';
@import '../../../../styles/utils/text-options.scss';
@import '../../../../styles/utils/mixins.scss';

.hamburger {
    cursor: pointer;
    display: flex;
    top: 0;
    right: 3px;
    position: absolute;

    &.hidden {
        display: none;
    }
}

.svg {
    width: 60px;
    height: 60px;
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.active .svg {
    transform: rotate(90deg);
}

.path {
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);

    stroke: $background-color-dark-darker;

    &:nth-child(1) {
        transform-origin: 36% 40%;
    }

    &:nth-child(2) {
        stroke-dasharray: 29 299;
    }

    &:nth-child(3) {
        transform-origin: 35% 63%;
    }

    &:nth-child(4) {
        stroke-dasharray: 29 299;
    }

    &:nth-child(5) {
        transform-origin: 61% 52%;
    }

    &:nth-child(6) {
        transform-origin: 62% 52%;
    }

}

.active .path:nth-child(1) {
    transform: translateX(9px) translateY(1px) rotate(45deg);
}

.active .path:nth-child(2) {
    stroke-dasharray: 225 299;
    stroke-dashoffset: -72px;
}

.active .path:nth-child(3) {
    transform: translateX(9px) translateY(1px) rotate(-45deg);
}

.active .path:nth-child(4) {
    stroke-dasharray: 225 299;
    stroke-dashoffset: -72px;
}

.active .path:nth-child(5) {
    transform: translateX(9px) translateY(1px) rotate(-45deg);
}

.active .path:nth-child(6) {
    transform: translateX(9px) translateY(1px) rotate(45deg);
}

#dark {
    .path {
        stroke: $background-color-light;
    }
}