@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.contact {
    &-top-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 2rem 6rem;
        justify-content: space-between;
        height: 10rem;

        @include sm {
            flex-direction: column;
            align-items: center;
            justify-content: end;
            padding: 2rem 1rem;
        }

        h1 {
            color: $text-color-grey;
            font-size: $text-size-26;
            padding: 0;
            margin: 0;

            @include sm {
                font-size: $text-size-22;
            }
        }
    }

    &-content {
        min-height: 700px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $background-color-light;

        &-main {
            width: 70%;
            height: inherit;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;

            @include md {
                width: 90%;
            }

            @include sm {
                width: 100%;
                flex-direction: column;
                align-items: center;
                padding: 2rem 0;
            }

            &-card {
                height: 550px;
                margin: 0 2rem;
                display: flex;
                flex-direction: column;

                @include sm {
                    height: fit-content;
                }

                &-left {
                    border-radius: 20px;
                    padding: 1rem 2rem;
                    background-color: $contact-card-background;
                    color: $text-color-white;
                    width: 25%;

                    @include sm {
                        width: 70%;
                    }

                    &-socials {
                        height: 60%;
                    }

                    &-info {
                        height: 40%;

                        h1 {
                            font-size: $text-size-16;
                        }

                        p {
                            font-size: $text-size-10;
                        }
                    }
                }

                &-right {
                    width: 35%;
                    height: 580px;

                    @include sm {
                        margin-top: 1rem;
                        width: 90%;
                    }

                    h1 {
                        margin: 0;
                        color: $contact-card-background;
                        font-size: $text-size-16;
                    }

                    .form-loading {
                        width: 6rem;

                        &::after {
                            content: "";
                            display: block;
                            border: 8px solid $background-color-light-header;
                            border-top: 8px solid $button-color-gradient-right;
                            border-radius: 50%;
                            margin: 0 auto;
                            width: 30px;
                            height: 30px;
                            animation: spin 1s linear infinite;
                        }
                    }

                    .info-container {
                        p {
                            font-size: $text-size-9;
                            text-align: center;
                            color: $text-color-grey;
                        }
                    }

                    form {
                        height: 100%;
                        position: relative;


                        @include sm {
                            display: flex;
                            flex-direction: column;
                        }

                        input,
                        textarea {
                            outline: none;
                        }

                        input {
                            width: 95%;
                            padding: 0 5%;
                            height: 40px;
                            border: 1px solid $text-color-grey;
                            border-radius: 10px;
                            margin: 10px 0;
                            color: $text-color-grey;

                            @include sm {
                                width: unset;
                            }
                        }

                        textarea {
                            resize: none;
                            border: 1px solid $text-color-grey;
                            border-radius: 10px;
                            height: 60%;
                            width: 95%;
                            padding: 10px 5%;
                            margin: 10px 0;
                            color: $text-color-grey;
                            font-family: inherit;

                            @include sm {
                                width: unset;
                            }
                        }
                    }

                    &-button-container {
                        height: 10%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-end;
                        justify-content: flex-end;
                        margin-left: 2.4rem;

                        @include sm {
                            margin: 0;
                            align-items: center;
                            justify-content: center;
                        }

                        button {
                            cursor: pointer;
                            background-color: $button-color-gradient-left;
                            background-image: linear-gradient(220deg, $button-color-gradient-right 6%, $button-color-gradient-left 100%);
                            color: $text-color-white;
                            border: none;
                            font-size: $text-size-10;
                            font-weight: $text-weight-600;
                            letter-spacing: 1px;
                            height: 80%;
                            width: 6rem;
                            border-radius: 40px;
                            transition-property: width;
                            transition-duration: .15s;
                            transition-timing-function: ease-in-out;

                            @include sm {
                                width: 80%;
                            }

                            &:hover {
                                width: 7rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

#dark {
    .contact {
        &-top-bar {
            h1 {
                color: $text-color-white;
            }
        }

        &-content {
            background-color: $background-color-dark-lighter;

            &-main {
                &-card {
                    &-left {
                        background-color: $contact-card-background-dark;
                        color: $text-color-white;
                    }

                    &-right {
                        h1 {
                            color: $text-color-white;
                        }

                        .form-loading {
                            &::after {
                                border: 8px solid $card-color-dark;
                                border-top: 8px solid $background-color-light;
                            }
                        }

                        .info-container {
                            p {
                                color: $text-color-white;
                            }
                        }

                        form {
                            input {
                                border: 1px solid $text-color-white;
                                color: $text-color-white;

                                &::placeholder {
                                    color: $text-color-white;
                                }

                                &:focus {
                                    color: $text-color-white
                                }

                                background-color: $background-color-dark-lighter;
                            }

                            textarea {
                                border: 1px solid $text-color-white;
                                color: $text-color-white;

                                &::placeholder {
                                    color: $text-color-white;
                                }

                                &:focus {
                                    color: $text-color-white
                                }

                                background-color: $background-color-dark-lighter;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}