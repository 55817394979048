@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.articles {
    background: transparent;
    width: 100vw;

    &-archive-top-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        background: transparent;
        padding: 2rem 0 0 0;

        @include sm {
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &-list {
        background: $background-color-light;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 1200px;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;

        &-link {
            text-decoration: none;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            padding: 2rem;
            color: $text-color-grey;
            max-width: 1400px;

            @include sm {
                flex-direction: column;
                align-items: center;
            }
        }

        &-empty {
            color: $text-color-grey;
            font-size: $text-size-14;
            margin: 3rem 0;
        }

        .loader {
            display: flex;
            justify-content: center;
            opacity: 1;
            transition: opacity .3s ease-in-out;

            @include md {
                width: 90%;
            }

            @include sm {
                width: 100%;
            }
        }

        .loader.loading {
            opacity: 0;
        }

        &-article {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 80%;
            height: fit-content;
            border-radius: 50px;
            margin: 0 0 2rem 0;
            transition: all .15s ease-in-out;
            box-shadow: 0 0 3px rgb(199, 199, 199);

            @include lgr {
                min-width: 1250px;
            }

            .article-logo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                margin-left: 20px;
                height: 180px;
                width: 180px;
                background-color: $article-image-background-color;

                @include sm {
                    width: 90%;
                    margin-left: 0;
                    max-width: 400px;
                }

                &-img {
                    width: 140px;
                }
            }

            .article-content {
                width: 80%;
                height: 70%;
                margin-left: 1rem;
                display: flex;
                flex-direction: column;

                @include sm {
                    margin-left: 0;
                    width: 100%;
                }

                .article-title {
                    color: $text-color-grey;
                    font-size: $text-size-18;
                    text-transform: uppercase;
                    margin-bottom: 1rem;

                    @include sm {
                        text-align: center;
                        font-size: $text-size-12;
                        margin-top: 1rem;
                    }
                }

                .article-description {
                    font-size: $text-size-12;

                    @include sm {
                        font-size: $text-size-10;
                    }
                }
            }
        }

        &-article:nth-child(1) {
            margin: 2rem;
        }

        &-article:hover {
            box-shadow: 1px 1px 10px rgb(178, 177, 177);
            transform: scale(1.003);
        }
    }
}

#dark {
    .articles {
        &-top-bar {
            h1 {
                color: $text-color-white;
                font-size: $text-size-26;
                padding: 0;
                margin: 0;
            }
        }

        &-list {
            background: $background-color-dark-lighter;

            &-empty {
                color: $text-color-white;
            }

            &-article {
                box-shadow: 0 0 3px rgb(199, 199, 199);

                .article-logo {
                    background-color: $article-image-background-color;
                }

                .article-content {
                    .article-title {
                        color: $text-color-white;
                    }

                    .article-description {
                        color: $text-color-white
                    }
                }
            }

            &-article:nth-child(1) {
                margin: 2rem;
            }

            &-article:hover {
                box-shadow: 1px 1px 10px rgb(125, 125, 125);
            }
        }
    }
}