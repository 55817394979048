@import '../../../../styles/utils/colors.scss';
@import '../../../../styles/utils/text-options.scss';
@import '../../../../styles/utils/mixins.scss';

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;

    &-label {
        color: transparent;
    }

    &-input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    &-span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $background-color-light-header;
        transition: 0.3s;
        border-radius: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border: 1px solid $button-color-gradient-left;
        min-width: 60px;

        &:before {
            position: absolute;
            content: "";
            height: 25px;
            width: 25px;
            left: 3px;
            bottom: 2.6px;
            background-color: $text-color-grey;
            border-radius: 50%;
            transition: 0.3s;
            z-index: 99999;
        }
    }

    &-image {
        display: block;
        height: fit-content;
        width: 35%;

        svg {
            position: relative;
            height: inherit;
            width: inherit;
        }
    }
}


.switch-input:checked+.switch-span {
    background-color: $contact-card-background-dark;
    border: 1px solid $button-color-gradient-left;

}

.switch-input:checked+.switch-span:before {
    transform: translateX(29px);
    background-color: $background-color-light-header;
}