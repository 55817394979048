@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.archives-nav {
    width: 100%;
    height: 4rem;
    background-color: $background-color-light-header;

    @include sm {
        height: fit-content;
    }

    &-list {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;

        @include sm {
            flex-direction: column;
        }

        &-element {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 40%;

            @include sm {
                padding: .5rem 0;
            }

            &-btn {
                cursor: pointer;
                font-size: $text-size-10;
                font-weight: $text-weight-600;
                background-color: transparent;
                color: $text-color-grey;
                border: none;
                outline: none;

                @include sm {
                    font-size: $text-size-12;
                }
            }
        }
    }
}

#dark {
    .archives-nav {
        background-color: $background-color-dark-darker;

        &-list {
            &-element {
                &-btn {
                    color: $text-color-white;
                }
            }
        }
    }

}