@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.logo-nav {
    width: fit-content;

    .logo-nav-first {
        position: relative;
        width: 50px;
        transition: transform .2s ease-in-out;
    }

    .logo-nav-second {
        right: 17px;
        position: relative;
        width: 32px;
        transition: transform .4s ease-in-out;
    }

    &:hover {
        .logo-nav-first {
            transform: scale(1.1);
        }

        .logo-nav-second {
            transform: scale(1.1);
        }
    }
}