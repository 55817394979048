@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.archives {
    &-about {
        width: 70%;

        @include sm {
            width: 90%;
        }

        &-content {
            font-size: $text-size-14;
            line-height: 2rem;
            color: $text-color-grey;
            margin-top: 2rem;

            @include sm {
                font-size: $text-size-12;
            }
        }
    }
}

#dark {
    .archives {
        &-about {
            &-content {
                color: $text-color-white;
            }
        }
    }
}