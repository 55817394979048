@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.archive-organizing-committee {
    width: 80%;

    .no-data {
        text-align: center;
        color: $text-color-grey;
        font-size: 1.4rem;
        line-height: 2rem;
        margin-top: 2rem;
        display: block;
        text-align: left;
    }

    @include sm {
        width: 90%;
    }

    &-list-element {
        margin-bottom: 30px;
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
        color: $text-color-grey;

        .list {
            margin: 0;
            padding: 0;
            list-style-type: none;
            margin-bottom: 2rem;

            &-element {
                display: flex;
                flex-direction: column;
                align-items: center;

                .member {
                    font-size: $text-size-12;
                    font-weight: $text-weight-600;

                    @include sm {
                        text-align: center;
                    }
                }

                .city {
                    font-size: $text-size-10;

                    @include sm {
                        text-align: center;
                    }

                    opacity: .7;
                }

                .college {
                    font-size: $text-size-10;

                    @include sm {
                        text-align: center;
                    }
                }
            }
        }

        .student {
            .title {
                font-size: $text-size-14;
                font-weight: $text-weight-600;

                @include sm {
                    display: block;
                    text-align: center;
                    width: 100%;
                }
            }

            &-list {
                margin: 0;
                padding: 0;
                margin-top: 30px;
                list-style-type: none;

                .list-element {
                    .member {
                        opacity: .8;
                    }
                }
            }
        }
    }
}

#dark {
    .archive-organizing-committee {
        .no-data {
            color: $text-color-white;
        }

        &-container {
            color: $text-color-white;
        }
    }
}