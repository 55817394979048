@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.committee {
    &-top-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 2rem 6rem;
        justify-content: space-between;
        height: 10rem;

        @include sm {
            padding: 2rem 1rem;
            align-items: center;
            justify-content: end;
            flex-direction: column;
        }

        h1 {
            color: $text-color-grey;
            font-size: $text-size-26;
            padding: 0;
            margin: 0;

            @include sm {
                font-size: $text-size-20;
                text-align: center;
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-height: 850px;
        height: fit-content;
        background: $background-color-light;
        padding: 1rem 0;

        .no-data {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100%;
            color: $text-color-grey;
            font-size: 1.4rem;
            line-height: 2rem;
            margin-top: 2rem;
        }

        ul {
            display: flex;
            flex-direction: column;
            list-style-type: none;

            @include md {
                padding: 0;
            }

            @include sm {
                padding: 0;
                width: 90%;
            }

            & .committee {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;

                @include sm {
                    margin-bottom: 30px;
                }

                &-member {
                    font-size: $text-size-12;
                    font-weight: $text-weight-600;
                    color: $text-color-grey;

                    @include md {
                        text-align: center;
                    }

                    @include sm {
                        text-align: center;
                        margin-bottom: 5px;
                    }
                }

                &-college {
                    font-size: $text-size-10;
                    color: $text-color-grey;

                    @include md {
                        text-align: center;
                    }

                    @include sm {
                        text-align: center;
                    }
                }
            }
        }
    }
}

#dark {
    .committee {
        &-top-bar {
            h1 {
                color: $text-color-white;
            }
        }

        &-content {
            background: $background-color-dark-lighter;

            .no-data {
                color: $text-color-white;
            }

            ul {
                & .committee {
                    &-member {
                        color: $text-color-white;
                    }

                    &-college {
                        color: $text-color-white;
                    }
                }
            }
        }
    }
}