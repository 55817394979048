@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.archive {
    &-program {
        width: 70%;

        @include md {
            width: 85%;
        }

        @include sm {
            width: 95%;
        }

        .list {
            list-style-type: none;
            padding: 0;
            margin-top: 2rem;

            &--no-data {
                text-align: center;
                color: $text-color-grey;
                font-size: 1.4rem;
                line-height: 2rem;
                margin-top: 2rem;
            }

            &-element {
                display: flex;
                flex-direction: row;
                margin-top: 1rem;
                position: relative;
                padding-bottom: 1rem;

                &-hour {
                    white-space: nowrap;
                    color: $text-color-grey;
                }


                &::after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    background-color: $card-shadow-color-light;
                    top: 100%;
                }

                &:last-child::after {
                    display: none;
                }

                &-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-left: 20px;

                    &-container {
                        &-box {
                            margin-bottom: 1.5rem;
                        }
                    }

                    .list-element-content-presenter {
                        color: $text-color-grey;
                        font-size: $text-size-10;
                        font-weight: $text-weight-600;
                        margin-bottom: .3rem;
                    }

                    &-description {
                        font-size: $text-size-10;
                        color: $text-color-grey;
                        opacity: .9;
                    }

                    &-title {
                        font-size: $text-size-12;
                        font-weight: $text-weight-600;
                        color: $text-color-grey;
                        margin-bottom: .6rem;
                    }

                    p {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
}

#dark {
    .archive {
        &-program {
            .list {
                &--no-data {
                    color: $text-color-white;
                }

                &-element {
                    &-hour {
                        color: $text-color-white;
                    }

                    &::after {
                        background-color: $card-shadow-color-light;
                    }

                    &-content {
                        .list-element-content-presenter {
                            color: $text-color-white;
                        }

                        &-description {
                            color: $text-color-white;
                            opacity: .7;
                        }

                        &-title {
                            color: $text-color-white;
                        }
                    }
                }
            }
        }
    }
}