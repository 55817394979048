@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.homepage-logo {
    &-element {
        position: relative;

        &-fact {
            width: fit-content;
            max-width: 500px;
            padding: 7px 15px;
            background: $background-color-light-darker;
            border-radius: 20px;
            box-shadow: 1px 1px 15px $card-shadow-color-dark-hover;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-5%, 0);
            transition-property: transform opacity;
            transition-duration: .2s;
            transition-timing-function: ease-out;

            span {
                text-align: center;
                color: $text-color-grey;
            }
        }

        picture {
            @include sm {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            img {
                width: 100%;
                position: relative;
                transition: transform .2s ease-out;


                @include sm {
                    margin-top: 31px;
                    width: 90%;
                    max-width: 375px;
                }

                @include md {
                    min-width: 700px;
                }

                @include lg {
                    min-width: 800px;
                }

                @include lgr {
                    min-width: 1000px;
                    max-width: 1100px;
                }

            }

            &:hover {
                img {
                    transform: scale(1.005);
                }

                &+.homepage-logo-element-fact {
                    transform: translate(0, 0);
                    opacity: 1;
                }
            }
        }
    }
}