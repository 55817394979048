$extra-small-width: 300px;
$small-width: 480px;
$medium-width: 768px;
$header-mobile: 890px;
$large-width: 1024px;
$larger-width: 1400px;

@mixin xxs {
    @media (max-width: #{$extra-small-width}) {
        @content;
    }
}

@mixin xs {
    @media (max-width: #{$small-width - 1px}) {
        @content;
    }
}

@mixin sm {
    @media (max-width: #{$medium-width - 1px}) {
        @content;
    }
}

@mixin hm {
    @media (max-width: #{$header-mobile}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$medium-width}) and (max-width: #{$large-width - 1px}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$large-width}) and (max-width: #{$larger-width - 1px}) {
        @content;
    }
}

@mixin lgr {
    @media (min-width: #{$larger-width}) {
        @content;
    }
}