@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.cookie-consent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 70px;
    bottom: 0;
    left: 0;
    z-index: 99999;
    padding: 0;
    margin: 0;
    background-color: $background-color-light-header;

    @include sm {
        flex-direction: column;
        justify-content: flex-start;
        height: 150px;
    }

    &.hidden {
        display: none;
    }

    .cookie-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;


        @include sm {
            width: 90%;
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    .cookie-text {
        margin-right: 10px;
        font-size: $text-size-10;
        color: $text-color-grey;
        font-weight: $text-weight-500;

        @include sm {
            text-align: center;
        }
    }

    .cookie-accept-button {
        cursor: pointer;
        padding: .5rem .8rem;
        margin-left: 10px;
        font-size: $text-size-10;
        font-weight: $text-weight-600;
        color: $text-color-white;
        border: none;
        background-image: linear-gradient(50deg, $button-color-gradient-left 5%, $button-color-gradient-right 106%);
        border-radius: 9999px;

        @include sm {
            padding: 1rem 2rem;
        }
    }
}

#dark {
    .cookie-consent {
        background-color: $background-color-dark-darker;

        .cookie-text {
            color: $text-color-white;
        }

        .cookie-accept-button {
            color: $text-color-white;
        }
    }
}