@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.comments {
    background: transparent;
    width: 100vw;

    &-top-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 2rem 6rem;
        justify-content: space-between;
        background: transparent;
        height: 10rem;

        @include sm {
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding: 6rem 1rem 0 1rem;
        }

        h1 {
            color: $text-color-grey;
            font-size: $text-size-26;
            padding: 0;
            margin: 0;

            @include sm {
                font-size: $text-size-22;
                margin-bottom: 20px;
            }
        }
    }

    &-main {
        min-height: 700px;
        background-color: $background-color-light;
        padding: 1rem 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &-wrapper {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 1200px;

        @include sm {
            width: 90%;
        }

        @include md {
            width: 85%;
        }

        @include lg {
            width: 70%;
        }
    }

    &-list {
        width: 100%;
        padding: 1.5rem 0;
        margin: 0;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(4, 24%);
        column-gap: 1rem;
        row-gap: 2rem;
        box-sizing: border-box;


        @include sm {
            grid-template-columns: repeat(2, 48%);
        }

        @include xs {
            grid-template-columns: repeat(1, 95%);
        }

        @include md {
            grid-template-columns: repeat(2, 48%);
        }

        @include lg {
            grid-template-columns: repeat(3, 32%);
        }
    }

    &-comment {
        list-style-type: none;
        padding: 1rem;
        border-radius: 30px;
        min-height: 140px;
        background: $card-color-light;

        .top-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .author {
                font-size: $text-size-10;
                font-weight: $text-weight-600;
                color: $text-color-grey;
                letter-spacing: -1px;
            }

            .time {
                font-size: $text-size-8;
                color: $text-color-grey;
            }
        }

        .content-wrapper {
            margin-top: 5px;

            .content {
                font-size: $text-size-10;
                color: $text-color-grey;
            }
        }
    }

    &-add-comment-show {
        height: 35px;
        color: $text-color-white;
        font-size: $text-size-12;
        padding: 0 1.5rem;
        border: none;
        outline: none;
        border-radius: 30px;
        background-color: $button-color-gradient-left;
        background-image: linear-gradient(220deg, $button-color-gradient-right 6%, $button-color-gradient-left 100%);
        font-weight: $text-weight-600;
        cursor: pointer;

        @include xs {
            margin: 0 auto;
        }
    }
}

#dark {
    .comments {
        &-top-bar {
            h1 {
                color: $text-color-white;
            }
        }

        &-main {
            background-color: $background-color-dark-lighter;
        }

        &-comment {
            background: $card-color-dark;

            .top-bar {
                .author {
                    color: $text-color-white;
                }

                .time {
                    color: $text-color-white;
                }
            }

            .content-wrapper {
                .content {
                    color: $text-color-white;
                }
            }
        }
    }
}