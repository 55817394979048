@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.archive-scientific-committee {
    width: 80%;

    .no-data {
        color: $text-color-grey;
        font-size: 1.4rem;
        line-height: 2rem;
        margin-top: 2rem;
        text-align: left;
        display: block;
        width: 100%;
    }

    .list {
        padding: 1rem 0;
        list-style-type: none;

        @include sm {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }

        &-element {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $text-color-grey;
            margin-bottom: 1.4rem;

            .element-text {
                margin-bottom: 5px;

                @include sm {
                    text-align: center;
                }
            }

            &-member {
                font-size: $text-size-12;
                font-weight: $text-weight-600;
            }

            &-college {
                color: $text-color-black;
            }

            &-city {
                opacity: .7;
            }
        }
    }
}

#dark {
    .archive-scientific-committee {
        .no-data {
            color: $text-color-white;
        }

        .list {
            &-element {
                color: $text-color-white;

                &-college {
                    color: $text-color-white;
                }
            }
        }
    }
}