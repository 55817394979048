@import '../../../../styles/utils/colors.scss';
@import '../../../../styles/utils/text-options.scss';
@import '../../../../styles/utils/mixins.scss';

.container {
    display: flex;
    flex-direction: row;
    width: 80%;

    .opacity-bg {
        background: black;
        height: 100vh;
        width: 100vw;
        position: absolute;
        opacity: 0.3;
        transition: opacity .3s ease-in-out;

        &.hidden {
            opacity: 0;
        }

        &.none {
            display: none;
        }
    }

    .menu {
        display: flex;
        align-items: center;
        width: 80%;
        list-style-type: none;
        padding-left: 0;

        .archive {
            position: relative;
            cursor: pointer;

            &-dropdown {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                width: 100%;
                height: fit-content;
                padding: .4rem 0;
                position: absolute;
                top: 100%;
                left: 0;
                background-color: $background-color-light;
                border: 1px solid $card-color-dark;
                border-radius: 10px;
                transition-property: transform, opacity;
                transition-duration: .2s;
                transition-timing-function: ease-in-out;
                transform: translateY(10%);
                z-index: 999999;

                &.hidden {
                    transform: translateY(0);
                    opacity: 0;
                }

                &-link {
                    text-decoration: none;
                    color: $text-color-grey;
                    padding: .2rem 0;
                }
            }
        }

        .menu-item {
            margin: 0 20px 0 0;
            padding: 0;
            text-decoration: none;
            color: $text-color-black;
            word-break: keep-all;
            font-weight: $text-weight-600;
            font-size: $text-size-11;

            .archive-title::after {
                content: '';
                border-right: 2px solid $text-color-grey;
                border-bottom: 2px solid $text-color-grey;
                width: 6px;
                height: 6px;
                position: absolute;
                right: -20%;
                top: 30%;
                transform: rotate(45deg);
                transition: transform .3s ease-in-out;
            }

            .archive-title[expanded="true"]::after {
                transform: rotate(-135deg) translate(-3px, -2px);
            }
        }

        @include hm {
            position: fixed;
            flex-direction: column;
            align-items: flex-start;
            background-color: $background-color-light-darker;
            height: 100vh;
            max-height: 100vh;
            width: 55%;
            margin: 0;
            right: -86%;
            transition: right .4s ease-in-out;
            padding: 2rem;

            &.open {
                right: -0%;
            }

            & li {
                margin-top: 1rem;
            }

            .menu-item {
                font-size: $text-size-14;
            }
        }
    }

    .menu-logo {
        width: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#dark {
    .container {
        .menu {
            .menu-item {
                color: $text-color-white;

                .archive-title::after {
                    border-right: 2px solid $text-color-white;
                    border-bottom: 2px solid $text-color-white;
                }
            }

            @include hm {
                background-color: $background-color-dark-darker;
            }
        }
    }
}
