@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.single-article {
    background: transparent;
    width: 100vw;

    &-top-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 2rem 6rem;
        justify-content: space-between;
        background: transparent;
        height: 10rem;

        @include sm {
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding: 6rem 1rem 0 1rem;
        }

        h1 {
            color: $text-color-grey;
            font-size: $text-size-22;
            padding: 0;
            margin: 0;

            @include sm {
                margin-bottom: 20px;
                font-size: $text-size-20;
            }

            @include xs {
                font-size: $text-size-16;
                text-align: center;
            }
        }
    }

    &-content {
        min-height: 800px;
        background-color: $background-color-light;
    }

    &-wrapper {
        width: 70%;
        margin: 0 auto;
        padding: 1.5rem 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include sm {
            width: 85%;
        }

        @include xs {
            width: 87%;
        }
    }

    &-authors {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    &-author {
        margin-left: 10px;
        font-size: $text-size-12;
        font-weight: $text-weight-600;
        color: $text-color-grey;

        @include xs {
            font-size: $text-size-10;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    &-title {
        margin-bottom: 20px;
        font-size: $text-size-12;
        font-weight: $text-weight-600;
        color: $text-color-grey;

        @include xs {
            font-size: $text-size-10;
        }
    }

    &-description {
        font-size: $text-size-12;
        font-weight: $text-weight-400;
        color: $text-color-grey;

    }
}

#dark {
    .single-article {
        &-top-bar {
            h1 {
                color: $text-color-white;
            }
        }

        &-content {
            min-height: 800px;
            background-color: $background-color-dark-lighter;
        }

        &-author {
            color: $text-color-white;
        }

        &-title {
            color: $text-color-white;
        }

        &-description {
            color: $text-color-white;
        }
    }
}