@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.add-comment {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: opacity .2s ease-in-out;
    z-index: 999999;

    &-background {
        width: 100%;
        height: 100%;
        background-color: $opacity-bg;
        z-index: 10;
    }

    &-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 70%;
        max-width: 660px;
        max-height: 660px;
        border-radius: 30px;
        background-color: $background-color-light-darker;
        z-index: 20;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include md {
            width: 75%;
        }

        @include hm {
            width: 80%;
        }

        @include sm {
            width: 85%;
        }

        @include xs {
            width: 100%;
            transform: translate(-50%, -40%);
            height: 90%;
            max-height: 90vh;
        }

    }

    &-top-wrapper {
        width: 100%;
        height: 10%;
        border-top-left-radius: 27px;
        border-top-right-radius: 27px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: $contact-card-background;

        @include xs {
            height: 7%;
        }
    }

    &-title {
        margin-left: 20px;
        color: $text-color-white;
        font-size: $text-size-12;
        font-weight: $text-weight-400;
    }

    &-close-button {
        width: 30px;
        height: 30px;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        &-img {
            width: 15px;
            height: 15px;
            filter: invert(100%) sepia(68%) saturate(495%) hue-rotate(247deg) brightness(119%) contrast(100%);

            svg {
                width: 15px;
                height: 15px;
            }
        }
    }

    &-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: fit-content;
        padding: 2rem 0;
    }

    &-status {
        font-size: $text-size-10;
        font-weight: $text-weight-600;
        text-align: center;
        display: none;
    }

    .green {
        color: $text-color-green;
        display: block;
        font-size: $text-size-12;
    }

    .red {
        color: $text-color-red;
        display: block;
    }

    .red-border {
        border: 2px solid $text-color-red;
    }

    &-author {
        outline: none;
        width: 50%;
        height: 30px;
        border: none;
        background-color: $background-color-light;
        border-radius: 30px;
        padding: 0 1rem;
        color: $text-color-grey;

        &::placeholder {
            color: $text-color-grey;
        }

        @include xs {
            width: 80%;
        }
    }

    &-content {
        background-color: $background-color-light;
        outline: none;
        resize: none;
        width: 100%;
        height: 100px;
        border: none;
        border-radius: 20px;
        padding: 1rem;
        font-family: inherit;
        font-size: $text-size-10;
        color: $text-color-grey;

        &::placeholder {
            color: $text-color-grey;
        }
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        margin-top: 1rem;

        @include xs {
            width: 80%;
        }
    }

    &-submit {
        margin-top: 1rem;
        background-color: $button-color-gradient-left;
        background-image: linear-gradient(220deg, $button-color-gradient-right 6%, $button-color-gradient-left 100%);
        color: $text-color-white;
        height: 30px;
        border: none;
        border-radius: 30px;
        padding: 0 3rem;
        font-weight: $text-weight-600;
        cursor: pointer;

        &:disabled,
        &[disabled] {
            opacity: 0.6;
        }
    }

    &-counter {
        font-size: .7rem;
        margin-left: auto;
        color: $text-color-grey;
    }
}

#dark {
    .add-comment {
        &-background {
            background-color: $opacity-bg;
        }

        &-wrapper {
            background-color: $background-color-dark-darker;

            @include md {
                width: 75%;
            }

            @include hm {
                width: 80%;
            }

            @include sm {
                width: 85%;
            }

            @include xs {
                width: 100%;
                transform: translate(-50%, -40%);
                height: 90%;
                max-height: 90vh;
            }

        }

        &-top-wrapper {
            background-color: $contact-card-background-dark;
        }

        &-title {
            color: $text-color-white;
        }

        .green {
            color: $text-color-green;
        }

        .red {
            color: $text-color-red;
        }

        .red-border {
            border: 2px solid $text-color-red;
        }

        &-author {
            background-color: $background-color-dark-lighter;
            color: $text-color-white;

            &::placeholder {
                color: $text-color-white;
            }
        }

        &-content {
            background-color: $background-color-dark-lighter;
            color: $text-color-white;

            &::placeholder {
                color: $text-color-white;
            }
        }

        &-counter {
            color: $text-color-white;
        }
    }
}