@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.maintenance {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $background-color-light;

    @include sm {
        justify-content: flex-start;
    }

    &-logo {
        height: auto;
        width: auto;
        width: 60vw;
        min-width: 600px;
        max-width: 1100px;

        @include sm {
            margin-top: 120px;
            width: 80vw;
            max-width: 450px;
            min-width: 1px;
        }
    }

    .text {
        margin: 0;
        font-weight: $text-weight-600;
        color: $text-color-grey;

        @include sm {
            text-align: center;
            padding: 0 1rem;
        }

        &-top {
            font-size: $text-size-18;
            margin: 40px 0 10px 0;

            @include sm {
                font-size: $text-size-14;
            }
        }

        &-bottom {
            font-size: $text-size-22;

            @include sm {
                font-size: $text-size-14;
            }
        }
    }
}

#dark {
    .maintenance {
        background-color: $background-color-dark-lighter;
    
        .text {
            color: $text-color-white;
        }
    }
}