@import '../../../../styles/utils/colors.scss';
@import '../../../../styles/utils/text-options.scss';
@import '../../../../styles/utils/mixins.scss';

.translation {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    &-selected {
        width: 30px;
        user-select: none;

        img {
            border-radius: 50%;
        }
    }

    select {
        border-radius: 5px;
        padding: 10px;
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        background-color: transparent;
        background-image: none;
        border: none;
        color: $text-color-grey;

        @include md {
            padding: 0;
        }

        &:focus-visible {
            outline: unset;
        }

        option {
            font-size: 18px;
            font-family: 'Open Sans', sans-serif;
            color: #555;
            background-color: rgb(247, 247, 247);
            background-image: none;
            font-size: 18px;
            height: 50px;
            padding: 15px;
            border: 1px solid rgb(41, 18, 18);

            &:hover {
                background-color: black;
            }

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}

#dark {
    .translation {
        select {
            color: $text-color-white;
        }
    }
}