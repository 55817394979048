@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';
$searchIcon: url('../../icons/base/search.svg');

.search {
    width: 250px;
    height: 40px;

    @include sm {
        width: 100%;
        max-width: 300px;
    }

    input {
        width: 100%;
        height: 60%;
        background: transparent;
        border: none;
        border-radius: 2px;
        border-bottom: 1px solid $text-color-grey;
        transition: background-color .2s ease-in-out;

        &:focus {
            outline: none;
            background-color: rgba($background-color-light, 0.4);

        }

        &::placeholder {
            font-weight: $text-weight-900;
        }
    }

    button {
        background: transparent;
        border: none;
        width: 30px;
        height: 30px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        bottom: 75%;
        left: 87%;

        img {
            width: 25px;
        }
    }
}

#dark {
    .search {
        button {
            img {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
            }
        }

        input {
            border-bottom: 1px solid $text-color-white;
            color: $text-color-white;

            &:focus {
                background-color: rgba($background-color-dark-lighter, 0.4);
            }

            &::placeholder {
                color: $text-color-white;
            }
        }
    }
}
