$background-color-dark-lighter: #464e57;
$background-color-dark-darker: #343a40;
$background-color-light-darker: rgb(240, 240, 240);
$background-color-light-middle: rgb(247, 245, 245);
$background-color-light: #fff;
$background-color-light-header: rgb(250, 250, 250);
$body-color-light-subpage: rgb(229 243 254);
$body-color-dark-subpage: #768291;
$card-color-light: rgb(244, 244, 245);
$card-color-dark: rgb(93, 93, 93);
$timeline-time-color-light: rgb(140, 140, 140);
$timeline-time-color-dark: rgb(193, 193, 193);

$button-color-gradient-right: rgb(75 154 249);
$button-color-gradient-left: rgb(41 78 127);

$article-image-background-color: rgb(245 249 255);

$card-shadow-color-light: rgb(190, 190, 190);
$card-shadow-color-light-hover: rgb(159, 159, 159);

$card-shadow-color-dark: rgb(56, 56, 56);
$card-shadow-color-dark-hover: rgb(39, 39, 39);

$contact-card-background: rgb(76 129 197);
$contact-card-background-dark: rgb(47, 122, 220);

$opacity-bg: rgba(0, 0, 0, .3)