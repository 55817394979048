@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.homepage {
    margin-top: 8rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: 100% 0%;
    background-repeat: no-repeat;
    background-image: url("../../../icons/homepage/cover.png");
    overflow-x: hidden;
    overflow-y: hidden;

    .main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 850px;
        width: 70%;
        transition: opacity .3s ease-in-out;

        @include sm {
            width: 90%;
            justify-content: flex-start;
        }

        @include md {
            width: 95%;
        }

        .paragraph {
            width: 50%;
            font-size: $text-size-14;
            margin-bottom: 60px;

            @include md {
                width: 70%;
            }

            @include sm {
                width: 90%;
                font-size: $text-size-12;
                font-weight: $text-weight-600;
                text-align: center;
                margin: 0 auto 60px auto;
            }
        }
    }

    .secondary {
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding: 0 16rem 2.4rem 16rem;
        background-color: $background-color-light;

        @include md {
            padding: 0 8rem 2.4rem 8rem;
        }

        @include sm {
            padding: 0 1rem 2.4rem 1rem;
        }

        h1 {
            color: $text-color-grey;
            font-size: $text-size-22;
            transition: all .3s ease-in-out;
        }

        p {
            color: $text-color-grey;
            font-size: $text-size-14;
            line-height: 2rem;
            font-weight: $text-weight-300;
            transition: all .3s ease-in-out;
        }
    }
}

#dark {
    .homepage {
        background-color: $background-color-dark-lighter;
        background-position: 100% 0%;
        background-repeat: no-repeat;
        background-image: url("../../../icons/homepage/cover.png");

        .main {
            .paragraph {
                color: $text-color-white;
            }
        }

        .secondary {
            background-color: $background-color-dark-lighter;

            h1 {
                color: $text-color-white;
            }

            p {
                color: $text-color-white;
            }
        }
    }
}