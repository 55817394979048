@import './styles/utils/text-options.scss';
@import './styles/utils/colors.scss';

.App {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    .app-core-none {
        display: none;
    }
}

html {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
}

body.subpage {
    background-image: url('./icons/pages/subpage-main-top-background.png');
    background-position: 195px 519px;
}

body#light.subpage {
    background-color: $body-color-light-subpage;
}

body#dark.subpage {
    background-color: $body-color-dark-subpage;
}

body#dark {
    background-color: $background-color-dark-lighter;
}