@import '../../../styles/utils/colors.scss';
@import '../../../styles/utils/text-options.scss';
@import '../../../styles/utils/mixins.scss';

.workshop-program {
    .no-data {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        color: $text-color-grey;
        font-size: 1.4rem;
        line-height: 2rem;
        margin-top: 2rem;
    }

    &-top {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 2rem 6rem;
        justify-content: space-between;
        background: transparent;
        height: 10rem;

        @include sm {
            padding: 2rem 1rem;
            align-items: center;
            justify-content: end;
            flex-direction: column;
        }

        h1 {
            color: $text-color-grey;
            font-size: $text-size-26;
            padding: 0;
            margin: 0;
        }
    }

    &-main {
        height: fit-content;
        min-height: 1200px;
        padding: 2rem 12rem;
        background-color: $background-color-light;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
        margin-top: 0;

        &--no-data {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        @include md {
            padding: 2rem 6rem;
        }

        @include sm {
            padding: 2rem 4rem 2rem 1rem;
        }
    }

    &-element {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 1rem;
        position: relative;

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: $card-shadow-color-light;
            position: absolute;
            bottom: 11px;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }


        &-time {
            white-space: nowrap;
            padding-right: .4rem;
            margin-top: 4px;
            color: $text-color-grey;
        }

        &-top {
            color: $text-color-grey;
            font-size: $text-size-14;

            &-title {
                font-size: $text-size-14;
                font-weight: $text-weight-600;
                color: $text-color-grey;

                @include sm {
                    font-size: $text-size-12;
                }
            }
        }

        &-bottom {
            padding: 1rem 0;

            &-element {
                padding: 10px 0;
                text-align: start;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &-presenter {
                    font-weight: $text-weight-600;
                    color: $text-color-grey;

                    @include sm {
                        font-size: $text-size-8;
                        line-height: 1.2em;
                    }

                    &-description {
                        color: $text-color-grey;
                    }
                }
            }
        }
    }
}

#dark {
    .workshop-program {
        .no-data {
            color: $text-color-white;
        }

        &-top {
            h1 {
                color: $text-color-white;
            }
        }

        &-main {
            background-color: $background-color-dark-lighter;
        }

        &-element {
            &::after {
                background-color: $card-shadow-color-light;
            }

            &-time {
                color: $text-color-white;
            }

            &-top {
                color: $text-color-white;

                &-title {
                    color: $text-color-white;
                }
            }

            &-bottom {
                &-element {
                    &-presenter {
                        color: $text-color-white;

                        &-description {
                            color: $text-color-white;
                        }
                    }
                }
            }
        }
    }
}